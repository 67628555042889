
import React, { Component } from 'react';
import styles from '../../../Assets/CSS/stylesheet_UHS';
import Grid from '@material-ui/core/Grid';
import {AppBar, Tabs, Tab, FormControl, InputLabel, Select} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Auth } from 'aws-amplify';
import axios from "axios";
import './window.css';
import Configuration from "../../../configurations";
import Loader from "../../loader";
import {Modal,Row,Col,Form} from "react-bootstrap";
import customStyle from "../../../Assets/CSS/stylesheet_UHS"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {withStyles} from "@material-ui/core/styles";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from "@material-ui/core/Button";
import Sample from "../../CommonScreens/sampleTextField";
import SampleName from '../../CommonScreens/sampleTextFieldName';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import configurations from "../../../configurations";
import CommonMultilineText from '../../CommonScreens/commonMultilineText';
import { getQueryParams } from '../../authentication/utils';
import MuiPhoneNumber from "material-ui-phone-number";

const CustomButton = withStyles(
    customStyle.viewBtn
)(Button);

const NextButton = withStyles(
    customStyle.doneBtn
)(Button);


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: false,
            loaderShow : false,
            association_logo : '',
            anchorEl:null,
            open:false,
            userName : '',
            profileModal : false,
            fname:'',
            lname:'',
            email:'',
            phone:'',
            countryCode : '',
            desc : '',
            imageName : '',
            imagePath : '',
            agentValid : false,
            agentURL: '',
            defaultCountryCode : "",
            imageErr : '',
            headerBackForEmployer:false,
            switchArray : [],
            ChannelName:localStorage.getItem("channelName")?localStorage.getItem("channelName"): sessionStorage.getItem("clientName"),
            isPhone: false
        };
    }

    componentDidMount() {
        
        this.setState({
            userName : sessionStorage.getItem('username')
        });
        this.state.isLogged = sessionStorage.getItem('isLogged');

        let data = {
            "clientId" : this.props.clientId
        }
        axios.post(configurations.baseUrl + '/enrollment/getClient', data)
            .then(response=>{
                if(response.data.response){
                    this.setState({
                        agentURL : configurations.AGENT_BASE_URL + response.data.response.clientName + '/' + this.props.agentId
                    })  
                }
            });
            axios.get(process.env.REACT_APP_BASE_URL + '/agentlogin/getChannelListByAgentId/' + sessionStorage.getItem('parentBrokerId'))
            .then(response =>{
                this.setState({switchArray: response.data.response})
            })
            if(Intl.DateTimeFormat().resolvedOptions().timeZone == "Asia/Calcutta"){
                this.setState({
                    defaultCountryCode : "+91"
                });
            }else{
                this.setState({
                    defaultCountryCode : "+1"
                });
            }
        let queryParams = getQueryParams();
        if (queryParams && queryParams.empid === undefined && sessionStorage.getItem('EMPLOYER_FLOW') === 'YES') {
            this.setState({headerBackForEmployer:true})
        }
    }
    handleClose = () => {
        this.setState({open:false,anchorEl:null})
    };

    logoutHandler = (event) => {
        this.setState({
            loaderShow : true
        });
        Auth.signOut();
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('isLogged', false);
        sessionStorage.setItem('isLogged', false);
        window.location.href = '/login';
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    handleOption = (option) => {
        this.setState({
            loaderShow : true
        });
        if(option === "Sign Out"){
            Auth.signOut();
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('isLogged', false);
            sessionStorage.setItem('isLogged', false);
            window.location.href = '/login';
        } else if(option === 'Profile'){
            this.clearData();    
            let email = sessionStorage.getItem('email');
            axios.get(Configuration.baseUrl + '/agentlogin/getAgent/' + email )
                .then(response => {

                    if(response.data.response){
                        let countyCode = '';
                        let phone = '';
                        if(response.data.response.phone){
                            if(response.data.response.phone.length === 13){
                                countyCode = response.data.response.phone.substr(0,3);
                                phone = this.formatPhoneNumber(response.data.response.phone.substr(2));
                            } else {
                                countyCode = response.data.response.phone.substr(0,2);
                                phone = this.formatPhoneNumber(response.data.response.phone);
                            }
                        } else {
                            countyCode = this.state.defaultCountryCode;
                            phone = '';
                        }


                        let filePath = response.data.response.image ? response.data.response.image.split('/') : [];
                        let fileName = '';

                        if(filePath.length > 0){
                            fileName = filePath[filePath.length - 1];
                        }

                        this.setState({
                            id: response.data.response.id,
                            agentId : response.data.response.brokerId,
                            fname : response.data.response.firstName,
                            lname : response.data.response.lastName,
                            email : response.data.response.email,
                            phone : phone,
                            desc : response.data.response.description ? response.data.response.description : '',
                            imagePath : response.data.response.image,
                            imageName : fileName,
                            countryCode : countyCode,
                            imageErr : '',
                            loaderShow : false,
                            profileModal : true,
                            open:false,
                            anchorEl:null
                        }, () => this.checkValidation());
                    }
                });
        }
    }

    handleMenuClick = (event) => {       
        this.setState({open:true,anchorEl:event.currentTarget})
      };

    setUserValue = (value, isValid, parentDetails) => {
        console.log(value);
        if(parentDetails.name === 'email'){
            if(isValid){
                this.state.email = value;
            } else {
                this.state.email = "";
            }
            this.checkValidation()
            this.state.isPhone = false
        }else if(parentDetails.name === 'firstname'){
            if(isValid){
                this.state.fname = value;
            } else {
                this.state.fname = "";
            }
            this.checkValidation()
            this.state.isPhone = false
        } else if(parentDetails.name === 'lastname'){
            if(isValid){
                this.state.lname = value;
            } else {
                this.state.lname = "";
            }
            this.checkValidation()
            this.state.isPhone = false
        } else if(parentDetails.name === 'phone'){
            if(isValid){
                this.state.isPhone = false
                this.state.phone = value;
            } else {
                this.state.phone = "";
            }
            if (!this.state.isPhone) {
                this.checkValidationPhone()
                this.state.isPhone = true
            }
        } else if(parentDetails.name === 'desc'){
            if(isValid){
                this.state.desc = value;
            } else {
                this.state.desc = "";
            }
            this.checkValidation()
            this.state.isPhone = false
        }

    }

    checkValidationPhone = () => {
        if(this.state.fname !== '' && this.state.lname !== '' &&  this.state.phone !== ""&&  (this.state.phone.length == 17) && this.state.email !== "" && this.state.desc !== ''){
            this.setState({
                agentValid : false
            });
        } else {
            this.setState({
                agentValid : true
            });
        }
    }
    checkValidation = () => {
        console.log('1st - ', this.state.phone !== "", ' ' ,this.state.phone.length == 10);
        if(this.state.fname !== '' && this.state.lname !== '' &&  this.state.phone !== ""&& (this.state.phone.length == 10 || this.state.phone.length == 17) && this.state.email !== "" && this.state.desc !== ''){
            this.setState({
                agentValid : false
            });
        } else {
            this.setState({
                agentValid : true
            });
        }
    }

    handleFileChange = (event) =>{
        if(event.currentTarget.files.length > 0) {
            this.setState({
                loaderShow: true,
                imageErr : ''
            });

            const file = event.target.files[0];  
            if (file.size > 1048576){
                this.setState({
                    imageErr : "File size cannot exceed more than 1MB",
                    loaderShow : false
                })
            } else {
                let bodyFormData = new FormData();
                bodyFormData.append('file', event.currentTarget.files[0]);
                this.state.imageName = event.currentTarget.files[0].name;

                axios(configurations.baseUrl + '/agentlogin/uploadImage', {
                    method: 'post',
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data' }
                }).then(response =>{
                    if(response.data.code === 200){
                        this.setState({
                            imagePath : response.data.response,
                            loaderShow : false
                        },() => this.checkValidation());
                    }
                })

            }
        }
    }

    updateAgentAndView=()=>{
        this.updateAgent();
        if(this.state.agentURL !== ''){
            // this.state.agentURL.select();
             document.execCommand("copy",);
            window.open(this.state.agentURL, '_blank');
         }
    }

    updateAgent = () =>{
        this.setState({
            loaderShow : true,
            isPhone: false
        });


            let newPhone = this.state.phone.replace(/[^a-zA-Z0-9]/g, '');

        let obj = {
            "firstName" : this.state.fname,
            "lastName": this.state.lname,
            "description": this.state.desc,
            "email":this.state.email,
            "phone": this.state.countryCode + newPhone.substr(1),
            image : this.state.imagePath
        }

        console.log('-- ',obj);
        axios.post(configurations.baseUrl + '/agentlogin/updateAgent', obj)
            .then(response => {
                if(response.data.code === 200){
                    this.setState({  
                        loaderShow : false,
                        profileModal : false    
                    }, () => this.clearData());
                }

            })
    }

    clearData = () =>{
        this.setState({
            fname:'',
            lname:'',
            email:'',
            phone:'',
            countryCode : '',
            desc : '',
            imageName : '',
            imageErr : ''
        });
    }
      
    SwitchClient=(event) =>{
        let channel=this.state.switchArray.filter(result => result.clientName==event);
        localStorage.setItem("channelName", event)
        if(channel.length>0){
        let obj={
            "custom:agentid": this.props.agentId,
            "given_name": "Netwell",
            "custom:associationId": channel[0].associationId,
            "custom:ChannelId": channel[0].clientId,
            "name": channel[0].firstName + " " + channel[0].lastName,
            "family_name": channel[0].lastName,
            "email": localStorage.getItem("email")
        }
        localStorage.setItem("SwitchAccount", JSON.stringify(obj))
        localStorage.setItem("brokerID", channel[0].brokerId)
        window.location.href = '/'
    }else{
        localStorage.removeItem("SwitchAccount")
        localStorage.removeItem("brokerID")
        window.location.href = '/'
    }
    }
    render() {
        const options=["Profile","Sign Out"]
        let currentScreen = '';
        this.state.isLogged = sessionStorage.getItem('isLogged');

        if (this.state.isLogged === 'true' || this.state.isLogged === true) {
            currentScreen = <div style={styles.LoginWrp}>
                <div style={styles.HeaderWrp}>
                    <Grid xs={6} style={styles.HeaderRightWrp} item={true}>
                        <span style={styles.LoginRight}>Member Enrollment</span>
                    </Grid>
                    <Grid xs={6} style={styles.HeaderLeftWrp} item={true}>

                        <span style={styles.LoginLeft} onClick={this.logoutHandler}>LOGOUT</span>
                    </Grid>
                </div>
            </div>
        } else {
            currentScreen = <div  >
                     <AppBar title="Agent Portal" position='static' style={{backgroundColor:'#ffffff',marginBottom: this.props.bottomMargin, display : '-webkit-inline-box'}}>
                         <Toolbar style={{ width:'50%',padding : '5px'}}>
                                 {/* <IconButton color="inherit" aria-label="Menu" style={{marginTop:'6px',marginRight:'6px', cursor : 'pointer'}} onClick={() => {window.location.replace('/')}} >
                                     <MenuIcon />
                                 </IconButton> 
                                 <Typography color="inherit" className="appTitle" onClick={() => {window.location.replace('/')}} style={{cursor : 'pointer'}}>
                                     Agent Portal
                                 </Typography>
                                 */}
                                 {/* <Grid container direction="row" style={{textAlign : 'center'}}> */}
                        {/* <Grid xs={12} sm={12} item={true}> */}
                            <div style={{ cursor : 'pointer', width : '7%'}} onClick={()=> window.location.replace(  sessionStorage.getItem('EMPLOYER_FLOW') === 'YES' ? this.state.headerBackForEmployer?"/": window.location.origin +'/manage-employers'  : '/')}>
                                {/* <ArrowBackIcon  style={{ color : '#162242',fontSize:'28px'}}/> */}
                            </div>

                        {/* </Grid> */}
                    {/* </Grid> */}
                                 <img style={{height:'40px'}} src={require('../../../Assets/Images/logo-innovation.png')} />
                         </Toolbar>
                         <Toolbar style={{width : '50%'}}>
                         


                             <div className="spacer"> 
                            
                             </div>
                             
                                 <Grid container spacing={2} style={{paddingLeft:"16.2vw", marginRight:"-1vw"}}>
                            <Grid xs={12} sm={8}>
                         <FormControl fullWidth style={{  borderRadius: 4, border: "solid 1px #c6cacc"}}>
                            <InputLabel id="demo-simple-select-label" style={{padding: 6}}>Channel Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.ChannelName}
                                label="Channel Name"
                                style={{paddingLeft:6, fontWeight: "bold"}}
                                onChange={(e)=>this.SwitchClient(e.target.value)}
                            >
                                <MenuItem  style={{fontWeight: "bold"}} value={localStorage.getItem("parentClientName")}>{localStorage.getItem("parentClientName")}</MenuItem>
                                 {this.state.switchArray && this.state.switchArray.length>0 && this.state.switchArray.map((data) =>
                                <MenuItem value={data.clientName}>{data.clientName}</MenuItem>
                                 )}
                            </Select>
                            </FormControl>
                            </Grid>
                            </Grid>
                                 <AccountCircle style={{color : '#0E234D',padding:'0 0.5rem',height:'45px',width:'45px',cursor:'pointer'}} onClick={this.handleMenuClick}/>
                                 <Menu keepMounted open={this.state.open} anchorEl={this.state.anchorEl} onClose={this.handleClose}
                                     // style={{left: '85%', top: '-55%'}}
                                       getContentAnchorEl={null}
                                       anchorOrigin={{
                                           vertical: 110,
                                           horizontal: 60,
                                       }}
                                       transformOrigin={{
                                           vertical: 80,
                                           horizontal: "right",
                                       }}>
                                     {options.map((op,i)=>{
                                         return <MenuItem key={i} value={op} onClick={this.handleOption.bind(this, op)}>
                                             {op}
                                         </MenuItem>
                                     })}

                                 </Menu>
                         </Toolbar>
                     </AppBar>
            </div>
        }


        return (
            <div>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
                {
                    currentScreen
                }

                {/*=================================== Profile Model ======================================*/}
                <Modal size="lg" show={this.state.profileModal} onHide={() => this.setState({profileModal:false, loaderShow : false}, () => this.clearData())} centered backdrop="static">
                    <Modal.Header style={styles.modal_header} closeButton>
                        <Modal.Title>Agent Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body  style={{  width : '100%' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        
                        <Grid container spacing={2} justify='center'>
                        <Grid item xs={12} sm={10} >
                        <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'agentid'} label={'Agent ID'} value={this.state.agentId} disable={true} style={customStyle.textFieldWrpAgent} length={25}  fieldType={'text'} errMsg={'Enter valid agent id'} helperMsg={'Agent id required'}  parentDetails={{name:'agentid'}}></Sample>
                        </Grid>
                        <Grid item xs={12} sm={5} >
                        <SampleName setChild={this.setUserValue.bind(this)} reqFlag={true} name={'firstname'} label={'First Name'} value={this.state.fname} disable={false} style={customStyle.textFieldWrpAgent} length={25}  fieldType={'fullName'} errMsg={'Enter valid first name'} helperMsg={'First name required'}  parentDetails={{name:'firstname'}}></SampleName>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                        <SampleName setChild={this.setUserValue.bind(this)} reqFlag={true} name={'lastname'} label={'Last Name'} value={this.state.lname} disable={false} style={customStyle.textFieldWrpAgent} length={25}  fieldType={'fullName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'}  parentDetails={{name:'lastname'}}></SampleName>
                        </Grid>
                        <Grid item xs={12} sm={5} >
                        <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'email'} label={'Email ID'} value={this.state.email} disable={true} style={customStyle.textFieldWrpAgent} length={50}  fieldType={'email'} errMsg={'Enter valid email Id'} helperMsg={'Email Id required'}  parentDetails={{name:'email'}}></Sample>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <div>
                                <MuiPhoneNumber
                                    name={"phone"}
                                    required id="filled-required"
                                    label={"Mobile No."}
                                    data-cy="user-phone"
                                    variant="filled"
                                    defaultCountry={"us"}
                                    onlyCountries={["us"]}
                                    autoComplete='off'
                                    value={this.state.phone}
                                    length={17}
                                    onChange={(e) => this.setUserValue(e, true, { name: 'phone' })}
                                    disable={false} style={customStyle.textFieldWrpAgent} fieldType={'phone'}
                                    errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'}
                                />
                                {this.state.phone.length == 17 ? null : <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-required mobileErr">Enter valid mobile no.</p>}
                                {/* <Sample setChild={this.setUserValue.bind(this)} reqFlag={true} name={'phone'} label={'Mobile No.'} value={this.state.phone} disable={false} style={customStyle.textFieldWrpAgent} length={10} fieldType={'phone'} errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'} parentDetails={{ name: 'phone' }}></Sample> */}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10} >
                        <CommonMultilineText setChild={this.setUserValue.bind(this)} req={true} name={'desc'} label={'Message'} value={this.state.desc} disable={false} style={customStyle.textFieldWrpAgent} length={200}  fieldType={'text'} errMsg={'Enter valid message'} helperMsg={'Message required'}  parentDetails={{name:'desc'}}></CommonMultilineText>
                        </Grid>
                        
                        </Grid>
                        <Grid container spacing={2} style={{marginLeft:'5%'}}>
                        <Grid item xs={12} sm={12} >
                                <input accept="image/jpeg, image/png" style={{display : 'none'}} id="icon-button-file" type="file" onChange={this.handleFileChange.bind(this)} />
                                    <label htmlFor="icon-button-file" style={{fontSize : 'small', color : 'rgba(0, 0, 0, 0.54)'}}>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>Upload Profile Picture
                                    </label>
                                    <span style={{marginLeft : '5px'}}>{this.state.imageName} </span>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <span style={{ color : '#f44336'}}>{this.state.imageErr} </span>
                        </Grid>
                        </Grid>
                        <hr/>
                        <Grid                        
                        spacing={1}
                        container
                        direction="row"
                        style={{marginLeft:'30%'}}
                        >
                            <Grid item xs={12} sm={2} >
                        
                        <NextButton style={{margin: '0', marginRight : '10px', width: '95px', height: '40px', color:'#fff', backgroundColor: this.state.agentValid ? '#ddd' : '#0E234D' }} disabled={this.state.agentValid} onClick={() => this.updateAgent()}>UPDATE</NextButton>
                        </Grid>
                        {/* <Grid item xs={12} sm={4} >
                        <NextButton style={{margin: '0', marginRight : '10px', width: '225px', height: '40px', color:'#fff', backgroundColor: this.state.agentValid ? '#ddd' : '#0E234D'}} disabled={this.state.agentValid} onClick={() => this.updateAgentAndView()} >UPDATE AND VIEW MY PAGE</NextButton>
                        </Grid> */}
                        <Grid item xs={12} sm={4} >
                        <CustomButton style={{height : '40px', color:'#fff', backgroundColor:'#0E234D'}} onClick={() => this.setState({profileModal:false, loaderShow : false}, () => this.clearData())}>CANCEL</CustomButton>
                        </Grid>
                        </Grid>

                    </Modal.Body>
                </Modal>
                {/*=================================== Profile Model ======================================*/}
            </div>

        )
    }
}

export default Header;